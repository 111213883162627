export enum RESPONSETYPE {
  MESSAGE = 'message',
  RESPONSE = 'response',
}

export enum ROOMTYPE {
  GROUP = 'group',
  USER = 'user'
}
export enum USERSTATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away'
}
export enum MessageType {
  TEXT = 'text',
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  PDF = 'pdf'
}

export const NO_IMAGE_USER_URL = 'https://icon-library.com/images/no-user-image-icon/no-user-image-icon-27.jpg'
export const NO_IMAGE_GROUP_URL = 'https://icon-library.com/images/group-icon-png/group-icon-png-15.jpg'
