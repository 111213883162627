import { HttpClient } from '@angular/common/http';
import { of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JWTToken } from '../model/jwt-token.model';
import jwt_decode from "jwt-decode";
import { oAuthPayload } from '../utlity/oAuth.config';
import { StorageService } from '../storage.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  constructor(private httpClient: HttpClient, private storageService: StorageService,) { }
  handleLogin(code: string | undefined | null) {
    if (code !== null && code !== undefined) {
      const payload = oAuthPayload(code);
      return this.httpClient.post(environment.oAuthTokenURL, JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).pipe(
        (tap(p => this.setToken(p)))
      )
    }
    return of()
  }
  DecodeToken(token: string): JWTToken {
    console.log(jwt_decode<JWTToken>(token));
    return jwt_decode<JWTToken>(token);
  }
  setToken(jwtToken: any) {

    const token = this.DecodeToken(jwtToken.id_token);
    this.storageService.store('Authorization', jwtToken.id_token);
    this.storageService.store('sessionID', token.sid);

  }
}
