import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit{
  src = '';
  constructor(private activatedRoute: ActivatedRoute) { }
  ngOnInit(): void {

    const search = this.activatedRoute.snapshot.queryParamMap.get('search');
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    const pathname = this.activatedRoute.snapshot.queryParamMap.get('pathname');

    this.src = `https://firebasestorage.googleapis.com${pathname}${search}&token=${token}`!

  }
  public showFreeFloatingBar = true;

}
