<div *ngIf="showChat">
  <div style="background-color: #fff;" >
    <div class="row justify-content-end m-0 p-0">
        <div class="col-md-4 col-sm-2 col-xl-3 col-lg-3" *ngIf="showContact"
        style="height: 85vh;overflow:scroll;"
        >
          <div class="offcanvas-md offcanvas-sm offcanvas-start" data-bs-backdrop="static" data-bs-backdrop="false"
            data-bs-scroll="true" tabindex="-1" style="background-color: #fff !important" id="offcanvasWithBothOptions"
            (mouseleave)="handleOffCanvas()" aria-labelledby="offcanvasWithBothOptionsLabel"
            *ngIf="rooms.length>0; else loading">
            <ng-container *ngTemplateOutlet="roomsTemplate; context: { $implicit: rooms }">
            </ng-container>
          </div>
          <span class="material-symbols-outlined" *ngIf="!isMobileDevice" (mouseenter)="handleOffCanvas()">
            menu
          </span>
          <div class="d-lg-none d-xl-none d-md-none  d-sm-block" (mouseenter)="handleOffCanvas()"
          style="height: 85vh;overflow:scroll;">
            <ng-container *ngTemplateOutlet="roomsIconOnlyTemplate; context: { $implicit: rooms }">
            </ng-container>
          </div>
          <div class="position-fixed mx-3 my-5 bottom-0 col-auto">
            <div class="d-flex align-items-center">
              <div>
                <img [src]="user.settings.profile_image" alt="..." width="40px" height="40px" class="rounded-circle">
              </div>
              <div class="mx-2 d-sm-none d-lg-block">{{user.name}}</div>

            </div>

          </div>
        </div>
        <div class="col-md-8 col-sm-10 col-xl-9 col-lg-9" *ngIf="showChatOnMobile">
          <div class="chat">
            <div class="d-flex justify-content-end px-2">
              <span style="cursor:pointer;position:absolute;right:0.5rem;top:0.5rem" class="material-symbols-outlined"
                (click)="handleShowChat()">
                close
              </span>
            </div>
            <div class="contact bar row">
              <div class="col-auto" (click)="handleBackButton()">
                <i class="fa-solid fa-chevron-left fa-xl d-sm-block d-md-none d-lg-none"></i>
              </div>
              <div class="col-auto">
                <img class="pic mx-1" [src]="selectedUser.logo" alt="selected-user-img" />
              </div>
              <div class="col-auto mx-1">
                <div class="name">
                  {{selectedUser.name}}
                </div>
                <div class="seen">
                  {{selectedUser.lastSeen}}
                </div>
              </div>
            </div>
            <div id="chat" #chats class="messages" *ngIf="loaded; else loading" (scroll)="handleScroll($event)">
              <div #messageContainer [ngClass]="{'message':true,'self':message.responseType==='message',
                'respondent':message.responseType==='response'}"
                *ngFor="let message of messageCollection;trackBy: trackByFn">
                <span *ngIf="message.msgType === 'text'">
                  <ng-container
                    *ngTemplateOutlet="!isLink(message) ? plainMessage : linkPreview; context: { $implicit: message }">

                  </ng-container>
                </span>
                <span *ngIf="message.msgType === 'image'">
                  <img [src]="message.thumbnail" style="height:15.5rem;object-fit:scale-down" alt="image"
                    (click)="handleOpen(message)" />
                </span>
                <span *ngIf="message.msgType === 'video' ">
                  <video width="250" height="140" controls>
                    <source [src]="message.msg" type="video/mp4">
                  </video>
                </span>
                <span *ngIf="message.msgType === 'pdf'" style="cursor:pointer ;" (click)="download(message)">
                  <i class="fa-solid fa-file-pdf" aria-hidden="true"></i>
                  {{message.name}}<br />
                  {{message.fileSize|fileSize}}
                </span>
                <div class="metadata">
                  {{message?.formattedTime|date:'dd/MMM hh:mm aaa'}}
                  <i *ngIf="message.responseType=='message'" class="tick" [ngClass]="{'tick-seen':message.seenByAll,
                'tick-not-seen':!message.seenByAll}"></i>
                </div>
              </div>

              <div *ngIf="(percentage$|async) as percentage">
                <div class="progress" *ngIf="percentage !== 100">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    [style.width.%]="percentage??0" [attr.aria-valuenow]="percentage" aria-valuemin="0"
                    aria-valuemax="100">
                    {{percentage/100|percent}}</div>
                </div>
              </div>
            </div>
            <div class="input">
              <!-- <label for="file" class="m-0">
                <i class="fas fa-paperclip"></i>
                <input type="file" id="file" style="display: none" name="image"
                  accept="image/gif,image/jpeg,image/jpg,image/png,video/mp4,application/pdf"
                  (change)="handleUpload($event)" data-original-title="upload photos">
              </label> -->
              <input (keyup)="onEnter($event)" placeholder="Type your message here!" type="text" [(ngModel)]="message"
                #messageInput />
              <i class="fas fa-paper-plane" (click)="sendMessage()"></i>

            </div>
          </div>
        </div>
    </div>
  </div>
</div>

<span class="material-symbols-outlined" style="position: absolute;bottom:0;right:0;cursor:pointer;" *ngIf="!showChat"
  (click)="handleShowChat()">
  <img src="../../assets/images/chat.svg" alt="chat">
</span>

<ng-template #plainMessage let-message>
  <span>{{message.msg}}</span>
</ng-template>

<ng-template #linkPreview let-message>
  <div class="card" [ngClass]="{'description-self':message.responseType==='message',
  'description-respondent':message.responseType==='response'}">
    <section *ngIf="message|linkPreview | async as preview ; else loading">
      <div class="row no-gutters">
        <div class="col-auto">
          <img *ngIf="preview?.images?.length" class="img-fluid preview-image" [src]="preview.images[0]"
            alt="Card image cap">
          <img *ngIf="!preview?.images?.length" class="img-fluid preview-image" [src]="preview.favicons[1]"
            alt="Card image cap">
        </div>
        <div class="col">
          <div class="card-block px-2">
            <h5 class="card-title">{{preview.title! | ellipsis:100}}</h5>
            <h5 *ngIf="preview?.title===''" class="card-title">{{preview.siteName}}</h5>
            <p class="card-text">{{preview.description| ellipsis:100 }}</p>
          </div>
        </div>
      </div>
      <div class="card-footer w-100 text-muted p-2">
        <a [href]="preview.url" target="_blank" class="card-link">{{preview.url |ellipsis:30}}</a>
      </div>
    </section>
  </div>
</ng-template>

<ng-template #loading>
  <div class="text-center h-100 d-flex justify-content-center align-items-center">
    <div class="spinner-grow text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #roomsIconOnlyTemplate let-rooms>
  <div class="contact cursor-pointer" [routerLink]="['/chat',room?.id]" routerLinkActive="active"
    *ngFor="let room of (rooms|skipCurrentUser:[companyID,currentUserID]);trackBy: trackUserByFn">
    <img class="pic contact-pic" [src]="room.url" alt="profile-pic" />
    <div class="badge badge-primary" *ngIf="room.unread_messages>0">
      {{getMessageCount(room)}}
    </div>
    <div class="name" *ngIf="isMobileDevice">
      {{room.name}}
    </div>
    <div [ngClass]="room.status"></div>
  </div>
</ng-template>

<ng-template #roomsTemplate let-rooms>
  <div class="contact cursor-pointer mx-3" [routerLink]="['/chat',room?.id]" routerLinkActive="active"
    *ngFor="let room of (rooms|skipCurrentUser:[companyID,currentUserID]);trackBy: trackUserByFn">
    <img class="pic contact-pic" [src]="room.url" alt="profile-pic" />
    <div class="name">
      {{room.name}}
    </div>
    <div class="badge badge-primary" *ngIf="room.unread_messages>0">
      {{getMessageCount(room)}}
    </div>
    <div class="message" [innerHTML]="room.lastMessage">
    </div>
    <div [ngClass]="room.status"></div>
  </div>
</ng-template>
