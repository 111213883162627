import { fromUnixTime } from "date-fns";
import { nt } from "../model/chat-room.model";
import { Message } from "../model/message.model";
import { MessageType, RESPONSETYPE } from "../utlity/constants";

export const mapMessages = (messages: Message[], currentUserID: string) => {
  return messages.map((message: Message) => mapMessage(message, currentUserID));
}

export const mapMessage = (message: Message, companyID: string): Message => ({

  msgType: (message.msgType === '' || message.msgType === undefined) ? MessageType.TEXT : message.msgType,
  msg: formatMessage(message),
  room: message.room,
  readBy: message.readBy ?? [],
  id: message.id,
  name: message.name,
  fileSize: message.fileSize ?? 0,
  seenByAll: ![...new Map(Object.entries(message.readBy)).values()].includes(false),
  unread: 0,
  source: 'FIRESTORE',
  thumbnail: getThumbnail(message),
  timeStamp: getDateTime(message.timeStamp),
  formattedTime: getDateTime(message.timeStamp),
  lastModified: message.lastModified,
  responseType: (message.sender === companyID) ? RESPONSETYPE.MESSAGE : RESPONSETYPE.RESPONSE,
});

const getDateTime = (timeStamp: Date | nt | undefined) => {
  const epoch = timeStamp !== null ? ((timeStamp) as nt).seconds : Date.now()
  return fromUnixTime(epoch)
}

const formatMessage = (message: Message) => {
  return message.msgType === MessageType.PDF ? encodeURI(message.msg!) : message.msg
}

const getThumbnail = (message: Message): string => {
  const messages = [
    { msgType: MessageType.TEXT, value: message.msg },
    // { msgType: MessageType.IMAGE, value: message?.msg?.replace("https://firebasestorage.googleapis.com", "https://ik.imagekit.io/yrgrf6bki4/tr:w-300,h-300") },
    { msgType: MessageType.AUDIO, value: message.msg },
    { msgType: MessageType.VIDEO, value: message.thumbnail },
  ]

  return messages.find(p => p.msgType === message.msgType)?.value as string ?? message.msg;
}


export const formatLastMessage = (message: string, msgType: string) => {
  const extension = [
  // {
  //   message: '📷  Sent a image file', type: MessageType.IMAGE
  // },
  {
    message: '📹  New  video file', type: MessageType.VIDEO
  },
  {
    message: '📎  Sent a pdf file', type: MessageType.PDF
  }
  ]
  return extension.find(p => p.type.includes(msgType))?.message ?? message.substring(0, 20).concat('...');

}

export const mapARPMessage = (messages: any, sender: string): Message[] => {
  return messages.map((item: any) => ({
    sender: item.user.id,
    seenByAll: true,
    id: item.id,
    quote: item.quotation,
    msg: item.message,
    msgType: MessageType.TEXT,
    type: item.type,
    user: item.user,
    source: 'ARP',
    timeStamp: new Date(item.sent_at),
    formattedTime: new Date(item.sent_at),
    responseType: (item.user.id == sender) ? RESPONSETYPE.MESSAGE : RESPONSETYPE.RESPONSE
  }));
}
