import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
/**
 * Storage Service for application, using local storage to store data
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: object) { }

  /**
   * Stores data object as JSON into key
   * @param key key value to store
   * @param data data object to store (internally converted to JSON String)
   */
  store(key: string, data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  /**
   * Checks if there exists a key
   * @param key key value to check
   */
  check(key: string): boolean {
    if (isPlatformBrowser(this.platformId)) {

      return (localStorage.getItem(key) !== null && localStorage.getItem(key) !== undefined) ? true : false;
    }
    return false;
  }

  /**
   * Retrieve data object of T from store
   * @param key key value to retrieve
   * @template T Data Model
   */
  get<T>(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      const data: string | null = localStorage.getItem(key);
      return JSON.parse(data as string) as T;
    }
    return null;
  }

  /**
   * remove key from store from store
   * @param key key value to retrieve
   */
  remove(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    }
  }

  /**
   * Clear local store
   */
  clear() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
  }
}

