import { StorageService } from './../storage.service';
import { chatAPI } from './../interceptor-context';
import { environment } from '../../environments/environment'
import { formatLastMessage, mapARPMessage, mapMessage } from './../mapper/message.mapper';
import { switchMap, zip, toArray, of, Subject, Observable, map, from, tap } from 'rxjs';
import { FIRESTORE } from '../config/firebase.config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/firestore';
import { ChatRoom, FireStoreRoom, Member } from '../model/chat-room.model';
import { PaginatedResponse } from '../model/pagenated.response';
import { UserInfo } from '../model/user-info.model';
import { RoomTypes } from 'src/enums/chat-room-type';
import { fromUnixTime, differenceInMinutes, formatRelative } from 'date-fns'
import { defaultLocale } from '../utlity/date-formatter';
import { enUS } from 'date-fns/locale';
import { MessageType, USERSTATUS } from '../utlity/constants';
import { arpAPI } from '../interceptor-context';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Message } from '../model/message.model';
import { User } from '../model/user.model';
import { Firestore, collectionData, collection,getDocs } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  userCollection: Array<User> = [];
  messageCollection$!: Observable<any[]>;
  messageCollection: any;
  item$!: Observable<any>;
  currentUser!: string;
  currentUserName!: string;
  selectedUser!: string;
  message!: string;
  onMessage:Subject<any> = new Subject();
  constructor(
    private firestore: AngularFirestore,
    private fs: Firestore,
    private storage: AngularFireStorage,
    private storageService:StorageService,
    private httpClient: HttpClient) { }


  getUsers(): Observable<User[]> {
    const usersDoc = from(getDocs(collection(this.fs, FIRESTORE.COLLECTION.USERS)));
    return usersDoc.pipe(
      map((doc) => doc.docs.map((item) => {
        const data = item.data() as User;
        const id = item.id;
        return { id, ...data };
      }))
    );
  }
  /**
   * Uploads the file to a chat room in firestore storage e.g filePath:rooms/{room_no}
   * @param file
   * @param filePath
   * @returns AngularFireUploadTask
   */
  upload(file: File, filePath: string): AngularFireUploadTask {
    return this.storage.upload(filePath, file);

  }
  /**
   * it returns the generated  url once the file is upload in firestore storage.
   * @param filePath
   * @returns
   */

  getDownloadLink(filePath: string) {
    return this.storage.ref(filePath).getDownloadURL();
  }

  generateThumbnail(downloadLink:string,filePath:string){
    return this.httpClient.post<string>(environment.CHATSERVERAPI.THUMBNAIL, { downloadLink,filePath })
  }

  getApprovedChatRooms(userCollection: User[]): Observable<ChatRoom[]> {
    const currentUser = this.storageService.get<UserInfo>('user_profile')!;
    const arp_rooms$ = this.getARPRooms().pipe(
      map((response: PaginatedResponse<ChatRoom>) =>
        response.results.map((room: ChatRoom) => this.updateUserStatus(room, userCollection))
      ));

    const firestore_room$ = this.getFireStoreRoom(currentUser.id);

    return zip(arp_rooms$, firestore_room$).pipe(
      map(([arp_rooms, firestore_rooms]) => {
        firestore_rooms?.forEach((p) => {

          const index = arp_rooms.findIndex(x => x.id == p.id)!
          if (index !== -1) {
            arp_rooms[index].lastMessage = p?.metadata?.lastMessage ?? '';
            arp_rooms[index].lastUpdated = p?.metadata?.lastUpdated?.seconds ? fromUnixTime(p?.metadata?.lastUpdated?.seconds!) : new Date(1,1,1970);
          }
        });
        return arp_rooms;
      })
    );
  }

  updateUserStatus(chatRoom: ChatRoom, userCollection: Array<User>): ChatRoom {
    const currentUser = this.storageService.get<UserInfo>('user_profile')!;
    const user = this.respondent(chatRoom, userCollection, currentUser);
    chatRoom.lastMessage = ''
    chatRoom.lastUpdated = new Date();


    if (!user) { return chatRoom; }
    const lastSeen = fromUnixTime(user?.lastSeen?.seconds!);
    const AWAY_DURATION = 1;//in minutes
    const difference = differenceInMinutes(new Date(), lastSeen);
    chatRoom.name = chatRoom.name?.replace(`${currentUser.name}/`, '')
    chatRoom.lastUpdatedText = difference > AWAY_DURATION ? formatRelative(lastSeen, new Date(), {
      locale: {
        ...enUS,
        formatRelative: defaultLocale,
      }
    }) : '';
    chatRoom.status = user?.isOnline ? (difference < AWAY_DURATION ? USERSTATUS.ONLINE : USERSTATUS.AWAY) : USERSTATUS.OFFLINE;

    return chatRoom;
  }

  respondent(chatRoom: ChatRoom, userCollection: Array<User>, currentUser: UserInfo) {

    const room_members = chatRoom.type === RoomTypes.USER ? chatRoom.members?.find(x => x.user_id !== currentUser.id)?.user_id : [];

    return userCollection.find(u => u.id == room_members);
  }

  sendMessage(message: string, chatRoom: ChatRoom, sender: string, msgType: string = MessageType.TEXT, name: string = '', fileSize: number = 0): Observable<any> {
    const roomID = `${chatRoom.id}`;
    const path = `/${FIRESTORE.COLLECTION.ROOMS}/${roomID}/${FIRESTORE.COLLECTION.MESSAGES}`;
    const usermap = new Map(chatRoom.members.map((chatRoom: Member) => [`${chatRoom.user_id}`, `${chatRoom.user_id}` === sender ? true : false]));
    this.message = '';
    return from(this.firestore.collection(path).add({
      sender,
      name,
      msg: message,
      msgType,
      fileSize,
      readBy: Object.fromEntries(usermap),
      timeStamp: firebase.serverTimestamp(),
      lastModified: firebase.serverTimestamp(),
    })).pipe(
      tap(this.updateLastMessage(roomID, message, sender, msgType)),
      tap(this.notifyUser(sender, chatRoom.members.flatMap(p => `${p.user_id}`),message,msgType))
    )
  }

  notifyUser(sender: string, members: string[], message: string,msgType:string): Observable<void> {
    return this.httpClient.post<void>(environment.CHATSERVERAPI.NOTIFICATION,
      { sender, members, header: formatLastMessage(message, msgType),message, msgType }, { context: chatAPI() }
    )
  }

  updateLastMessage(roomID: string, message: string, sender: string, msgType: string): Observable<void> {
    const metadata = {

      lastMessage: formatLastMessage(message, msgType),
      sender: sender,
      lastUpdated: firebase.serverTimestamp()

    }
    return from(this.firestore.collection(FIRESTORE.COLLECTION.ROOMS).doc(roomID).update({ metadata }))
  }

  createUser(): Observable<void> {
    const access_token = this.storageService.get<string>('Authorization');
    return this.httpClient.post<void>(environment.CHATSERVERAPI.CREATEUSER,
      { access_token }, { context: chatAPI() }
    );
  }

  createRoom(users: ChatRoom) {
    const access_token = this.storageService.get<string>('Authorization');
    const message = { id: 1, messages: [] };
    return this.isRoomExist(users.id)
      .pipe(switchMap(exist => {
        if (!exist)
          return this.httpClient.post<Message[]>(environment.CHATSERVERAPI.CREATEROOM, { users, access_token });
        else
          return of(message);
      }))

  }
  /**
   * Get messages for the selected room and current user id.
   * @param chatRoom
   * @param sender
   * @returns messages from firestore and arp.
   *
   */
  getMessagesByRoomID(chatRoom: ChatRoom, sender: string): Observable<[Message[], Message[]]> {
    const firestoreMessage$ = this.getFirestoreMessage(chatRoom, sender);
    const arpMessage$: Observable<Message[]> = this.createRoom(chatRoom).pipe(map((p: any) => mapARPMessage(p.messages, sender)));
    return arpMessage$.pipe(
      switchMap(user => zip([of(user), firestoreMessage$])),
    )
  }

 /**
   * Get messages for the selected room and current user id.
   * @param chatRoom
   * @param sender
   * @returns messages from firestore and arp.
   *
   */
  getFirestoreMessage(chatRoom: ChatRoom, sender: string): Observable<Message[]> {
    return this.firestore
      .collection(`${FIRESTORE.COLLECTION.ROOMS}/${chatRoom?.id}/${FIRESTORE.COLLECTION.MESSAGES}`,
        ref => ref.where('msg', '!=', ''))
      .snapshotChanges()
      .pipe(
        map((items) => items
          .map((item: any) => {
            const data = { id: item.payload.doc.id, ...item.payload.doc.data(), room: chatRoom.id }
            const formattedMessage = mapMessage(data, sender);
            return formattedMessage;
          }
          ).sort((a: Message, b: Message) =>
            new Date(a.timeStamp as Date).getTime() - new Date(b.timeStamp as Date).getTime())
        )
      );
  }

/**
 * mark all messages as read for the selected room
 * @param room
 * @param sender
 * @returns
 */
  markAllAsRead(room: ChatRoom, sender: string): Observable<void> {
    let readBy: any = {};
    readBy[`readBy.${sender}`] = true;
    return from(this.firestore.collection(`rooms/${room.id}/messages`)
      .ref
      .where(`readBy.${sender}`, '==', false)
      .get())
      .pipe(
        map(p => p.docs
          .forEach(
            (p: any) => {
              this.markAsRead(p, readBy);
            })
        ));
  }

  markAsRead(p: any, data: any) {
    p.ref.update(data)
    p.ref.update({ lastModified: firebase.serverTimestamp() })
  }

  getFireStoreRoom = (sender: number): Observable<FireStoreRoom[]> => {
    const roomsQuery = firebase.query(collection(this.fs, FIRESTORE.COLLECTION.ROOMS), firebase.where(`users.${sender}`, '==', true));
    const rooms$ = from(getDocs(roomsQuery));

    return rooms$.pipe(
      switchMap(x => x.docs),
      map((d: any) => (
        {
          id: d.id,
          ...d.data()
        })),
      toArray()
    )
  }

  getARPRooms(): Observable<PaginatedResponse<ChatRoom>> {
    return this.httpClient.get<PaginatedResponse<ChatRoom>>(environment.ARPAPI.CHATROOMS, {
      context: arpAPI()
    })

  }

  downloadFile(url: string) {
    return this.httpClient.get<any>(url,
      { responseType: "blob" as "json" })
  }

  async updateVideoThumbnail(id: string, thumbNailUrl: string,roomID:number):Promise<void> {
    const path = `/${FIRESTORE.COLLECTION.ROOMS}/${roomID}/${FIRESTORE.COLLECTION.MESSAGES}`;
    await this.firestore.collection(path).doc(id).update({thumbnail:thumbNailUrl,lastModified:firebase.serverTimestamp()});
  }
  messageListner(currentUser:string) {
    return this.firestore.collection(FIRESTORE.COLLECTION.ROOMS).ref.onSnapshot(
      (snapshot) => {
        const id = snapshot.docChanges()[0].doc.id;
        let room = snapshot.docChanges()[0].doc.data() as ChatRoom;
        room.id = parseInt(id);
        this.onMessage.next(room)
      })
  }
  isRoomExist(roomID: number): Observable<boolean> {
    return this.firestore.collection(FIRESTORE.COLLECTION.ROOMS, ref => ref.where('msg', '!=', ''))
      .doc(`${roomID}`)
      .get()
      .pipe(map(p => p.exists))
  }
}
