import { ChatService } from './../chat-box/chat.service';
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { lastValueFrom } from "rxjs";
import { UserManagerService } from "../user/user.manager";
import { LoginService } from "./login.service";
import * as firebase from 'firebase/firestore';
import { OAuthService } from './oAuth.service';
import { UserInfo } from '../model/user-info.model';

export abstract class LoginManager {

}
@Injectable({
  providedIn: 'root'
})
export class LoginManagerService implements LoginManager {
  constructor(private loginService: LoginService,
    private userService: UserManagerService,
    private chatService: ChatService,
    private oAuthService: OAuthService,
    private afAuth: AngularFireAuth) { }


  async loginWithAgridence(code: string | undefined | null) {
    await lastValueFrom(this.oAuthService.handleLogin(code))
    const userInfo = await lastValueFrom(this.userService.userInfo());
    await this.signInFirestore(userInfo);
    await lastValueFrom(this.chatService.createUser());
    await lastValueFrom(this.userService.updateUserStatus(`${userInfo.id}`, { isOnline: true, lastSeen: firebase.serverTimestamp() }));

  }

  async signInFirestore(userInfo:UserInfo) {
    const jwt_token = await lastValueFrom(this.loginService.getJWT(`${userInfo.id}`));
    await this.afAuth.signInWithCustomToken(jwt_token.token)
  }
}
