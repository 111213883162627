import { StorageService } from './../storage.service';
import { from, map, Observable, switchMap, tap, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {}
  login(email: string, password: string): Observable<Login> {
    return this.httpClient
      .post<Login>(environment.ARPAPI.LOGIN, { email, password })
      .pipe(
        tap((res) => {
          console.log(res)
          this.setSession;
        })
      );
  }
  getJWT(userID: string): Observable<Login> {
    const access_token = this.storageService.get<string>('Authorization');
    return this.httpClient
      .post<Login>(environment.CHATSERVERAPI.TOKEN, { userID, access_token })
      .pipe(tap(this.setJWTSession));
  }

  private setSession = (token: Login) => {
    this.storageService.store('Authorization', token.token);
  };
  private setJWTSession = (token: Login) => {
    this.storageService.store('jwt_token', token.token);
  };
}
export interface Login {
  token: string;
}
