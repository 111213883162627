
const formatRelativeLocale = {
  lastWeek: "'Last' eeee 'at' p",
  yesterday: "'Last seen at' p",
  today: "'Today at' p",
  tomorrow: "'Tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: 'P'
}
export const defaultLocale = (
  token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token];
