import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-chat-box-parent',
  templateUrl: './chat-box-parent.component.html',
  styleUrls: ['./chat-box-parent.component.scss'],
})
export class ChatBoxParentComponent {
  get isMobile() {
    return this.storageService.get('source') === 'mobile';
  }

  constructor(private storageService: StorageService) {}
}
