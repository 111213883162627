
<ngx-extended-pdf-viewer *ngIf="src"
  #pdfViewer
  backgroundColor="#0B210C"
  [customToolbar]="additionalButtons"
  [src]="src"
  [height]="'100vh'"
  [useBrowserLocale]="true"
  [zoom]="'page-fit'"
  [textLayer]="true">
</ngx-extended-pdf-viewer>

<ng-template #additionalButtons>
  <div id="toolbarViewer" style="background-color:#fff">
    <div id="toolbarViewerLeft">
      <pdf-toggle-sidebar></pdf-toggle-sidebar>
      <div class="toolbarButtonSpacer"></div>
      <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
      <pdf-paging-area></pdf-paging-area>
    </div>
    <div id="toolbarViewerRight">
      <pdf-open-file></pdf-open-file>
      <pdf-presentation-mode></pdf-presentation-mode>
      <pdf-print></pdf-print>

      <pdf-download></pdf-download>
      <div class="verticalToolbarSeparator hiddenSmallView"></div>
      <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
    </div>
    <pdf-zoom-toolbar ></pdf-zoom-toolbar>
  </div>
</ng-template>

