// @ts-nocheck
import { StorageService } from './../storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { formatLastMessage, mapMessage } from './../mapper/message.mapper';
import { FIRESTORE } from '../config/firebase.config';
import {
  MessageType,
  NO_IMAGE_GROUP_URL,
  NO_IMAGE_USER_URL,
  ROOMTYPE,
} from './../utlity/constants';
import {
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  map,
  Observable,
  switchMap,
  last,
  first,
  tap,
  lastValueFrom,
  Subject,
} from 'rxjs';
import { ChatRoom } from '../model/chat-room.model';
import { UserInfo } from '../model/user-info.model';
import { saveAs } from 'file-saver';
import { Message } from '../model/message.model';
import { caculateUnreadMessage } from '../utlity/utlity';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  Gallery,
  ImageItem,
  ImageSize,
  ThumbnailsPosition,
  VideoItem,
  YoutubeItem,
  GalleryItem,
  IframeItem,
} from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';

import { DocumentReference } from '@firebase/firestore';
import { AllowedImage, AllowedVideo } from '../config/chat-configuration';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ChatService } from '../chat-box/chat.service';

@Component({
  selector: 'app-chat-list-mobile',
  templateUrl: './chat-list-mobile.component.html',
  styleUrls: ['./chat-list-mobile.component.scss'],
})
export class ChatListMobileComponent {
  rooms: Array<ChatRoom> = [];
  private _album: Array<GalleryItem> = [];
  showContact: boolean = true;
  messageCollection$!: Observable<Message[]>;
  messageCollection: Array<Message> = [];
  selectedRoom: ChatRoom;
  item$!: Observable<any>;
  myOffcanvas: any;
  bsOffcanvas: any;
  percentage$!: Observable<number | undefined>;
  public textArea: string = '';
  user!: UserInfo;
  loaded: boolean = false;
  currentUserName!: string;
  selectedUser: ActiveUser = { name: '', logo: '', lastSeen: '', roomID: 0 };
  message!: string;
  showChat: boolean = true;
  quote: any;
  roomSelected$ = new Subject<ChatRoom | null>();
  @ViewChild('chats') list?: ElementRef<HTMLDivElement>;
  @ViewChild('messageInput') messageInput!: ElementRef;
  @ViewChildren('messageContainer') messageContainers!: QueryList<ElementRef>;
  ngAfterViewInit() {
    this.messageContainers.changes.subscribe(() => {
      this.scrollToBottom();
    });
  }
  constructor(
    private chatService: ChatService,
    public gallery: Gallery,
    public lightbox: Lightbox,
    public _sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.loadRooms();
    this.chatService.messageListner(this.currentUserID);

    this.chatService.onMessage.subscribe((p) => this.handleMessage(p));
    this.user = this.storageService.get<UserInfo>('user_profile')!;
    // this.initializeSelectedRoom();
    this.listenToSelectedRoomChange();
  }

  /**

  get approved chat rooms from ARP BE and set users status (online|offline|away).
  The chat rooms are obtained by first getting the users from firestore and then
  setting up status for every room where type is user.

  For each chat room, the user's logo is added to the room's URL ,once data is
  received the by default first room is selected.

  */
  get isMobileDevice() {
    return this.storageService.get('source') === 'mobile';
  }

  // TODO: bry - remove
  initializeSelectedRoom() {
    this.route.params.subscribe((params) => {
      const selectedRoomId = Number(params['id']);
      const chatRoom = this.rooms.find((p) => p.id === selectedRoomId);
      this.roomSelected$.next(chatRoom);
      console.log('from params', chatRoom, this.rooms, selectedRoomId);
    });
  }

  listenToSelectedRoomChange() {
    this.roomSelected$.subscribe((room) => {
      if (Object.entries(room).length !== 0) {
        this.messageCollection = [];
        this.loaded = false;
        this.selectedRoom = room;
        this.getMessages(room);
      }
      console.log(this.selectedRoom);
    });
  }

  loadRooms() {
    return this.chatService
      .getUsers()
      .pipe(
        map((user) => user),
        switchMap((user) => this.chatService.getApprovedChatRooms(user)),
        map((rooms: ChatRoom[]) =>
          rooms.map((room) => {
            room.url = this.getUser(room).logo;
            return room;
          })
        ),
        tap((rooms) => {
          this.rooms = [...rooms].sort(sortRoomByDate);
        }),
        switchMap(() => this.route.params)
      )
      .subscribe((routeParams) => {
        const selectedRoomId = Number(routeParams['id']);
        const chatRoom = this.rooms.find((p) => p.id === selectedRoomId);
        this.roomSelected$.next(chatRoom);
      });
  }

  /**
   * Attaching to subscription for every room when new message received in a room.
   */
  handleMessage(room: ChatRoom) {
    const updatedRoom = this.rooms.find((p) => p.id == room.id);
    if (this.rooms.length) {
      this.chatService
        .getFirestoreMessage(updatedRoom, this.currentUserID)
        .pipe(
          tap((messages) => this.refreshMessages(messages, updatedRoom)),
          tap((_) => {
            const notifiedRoom = this.rooms.find((p) => p.id == room.id);
            this.rooms = [
              { ...notifiedRoom },
              ...this.rooms.filter((p) => p.id !== room.id),
            ];
          })
        )
        .subscribe();
    }
  }

  /**
   * Update the existing message collection with difference
   * and mark all message as read.
   * @param messages
   * @param chatRoom
   */
  async refreshMessages(messages: Message[], chatRoom: ChatRoom) {
    if (messages.length) {
      if (chatRoom?.id === this.selectedRoom?.id) {
        await lastValueFrom(
          this.chatService.markAllAsRead(this.selectedRoom, this.currentUserID)
        );
        this.messageCollection = messages;
      }
      const unread_message = caculateUnreadMessage(
        messages,
        this.currentUserID
      );
      const latestMessage = mapMessage(
        messages[messages.length - 1],
        this.currentUserID
      );
      this.rooms.find((c) => c.id === messages[0].room)!.unread_messages =
        unread_message;
      this.rooms.find((c) => c.id === messages[0].room)!.lastMessage =
        formatLastMessage(latestMessage.msg!, latestMessage.msgType!);
    } else {
      this.messageCollection = [];
    }
  }

  /**
   * Send message to firestore if not blank.
   * @param msgType
   * @param name
   * @param fileSize
   * @returns void
   */
  async sendMessage(
    msgType: string = MessageType.TEXT,
    name: string = '',
    fileSize: number = 0,
    filePath = ''
  ) {
    if (this.message === '') return;
    const document = await lastValueFrom(
      this.chatService.sendMessage(
        this.message,
        this.selectedRoom,
        this.currentUserID,
        msgType,
        name,
        fileSize
      )
    );
    this.generateThumbnail(msgType, filePath, document);
    this.message = '';
    this.scrollToBottom();
  }

  /**
   * It take room as parameter and
   * returns messages from ARP and Firestore
   * and mark them all as read.
   * @param chatRoom
   */
  getMessages(chatRoom: ChatRoom) {
    if (chatRoom.id === 0) {
      return;
    }
    if (this.isMobileDevice) {
      this.showChatOnMobile = true;
      this.showContact = false;
    }
    this.chatService
      .getMessagesByRoomID(chatRoom, this.currentUserID)
      .subscribe(([arpmessages, firestoremessage]) => {
        this.rooms.find((c) => c.id === chatRoom.id).unread_messages = 0;
        this.messageCollection = [...arpmessages, ...firestoremessage];
        this.selectedUser = this.getUser(chatRoom);
        this.loaded = true;
        this.scrollToBottom();
      });
    this.chatService
      .markAllAsRead(this.selectedRoom, this.currentUserID)
      .pipe(first())
      .subscribe();
  }

  onChatRoomClicked(room: ChatRoom) {
    this.router.navigate(['/chat', room.id]);
    // this.roomSelected$.next(room);
  }

  onBackClicked() {
    this.router.navigate(['/chat']);
    // this.roomSelected$.next(null);
    this.showContact = true;
  }
  /**
   * It takes room as parameter and retrive the messages for it
   * from ARP and firestore.
   * @param chatRoom
   */
  // handleSelectedRoom(id: number | undefined, isBack: boolean = false) {
  //   const chatRoom = this.rooms.find((p) => p.id === id);
  //   console.log(chatRoom, id);
  //   if (isBack) {
  //     this.router.navigate(['chat']);
  //   } else {
  //     this.router.navigate(['chat', chatRoom?.id]);
  //   }
  //   this.messageCollection = [];
  //   this.loaded = false;
  //   this.selectedRoom = chatRoom;
  //   this.getMessages(chatRoom);
  // }

  /**
   * It will upload the file to firestore storage
   * and call send message once upload is finished..
   * @param event
   */
  // handleUpload(event: any) {
  //   const extension = [
  //     {
  //       name: MessageType.IMAGE,
  //       type: AllowedImage,
  //     },
  //     {
  //       name: MessageType.VIDEO,
  //       type: AllowedVideo,
  //     },
  //     {
  //       name: MessageType.PDF,
  //       type: ['application/pdf'],
  //     },
  //   ];

  //   const file: File = event.target.files[0];
  //   const filePath = `${FIRESTORE.COLLECTION.ROOMS}/${this.selectedRoom.id}/${this.currentUserID}_${file.name}`;
  //   const messageType = extension.find((p) => p.type.includes(file.type))?.name;

  //   const task = this.chatService.upload(file, filePath);
  //   this.percentage$ = task.percentageChanges();
  //   task
  //     .snapshotChanges()
  //     .pipe(
  //       last(),
  //       switchMap(() => this.chatService.getDownloadLink(filePath))
  //     )
  //     .subscribe((p) => {
  //       this.message = p;
  //       this.sendMessage(messageType, file.name, file.size, filePath);
  //     });
  // }

  async generateThumbnail(
    messageType: string,
    filePath: string,
    document: DocumentReference
  ) {
    if (messageType === MessageType.VIDEO) {
      const thumbnailUrl = await lastValueFrom(
        this.chatService.generateThumbnail(this.message, filePath)
      );
      await this.chatService.updateVideoThumbnail(
        document.id,
        thumbnailUrl,
        this.selectedRoom.id
      );
    }
  }

  /**
   * Formating current logged in user status(like last seen,last meesage,logo) for selected room
   * @param chatRoom
   * @returns
   */
  getUser(chatRoom: ChatRoom): ActiveUser {
    const user =
      chatRoom.producer_user?.id == this.currentUserID
        ? chatRoom.consumer_user
        : chatRoom.producer_user;

    return chatRoom.type === ROOMTYPE.USER
      ? {
          roomID: chatRoom.id,
          name: chatRoom.name,
          logo: user.profile_image_url ?? NO_IMAGE_USER_URL,
          lastSeen: chatRoom.lastUpdatedText!,
        }
      : {
          roomID: chatRoom.id,
          name: chatRoom.name,
          logo: NO_IMAGE_GROUP_URL,
          lastSeen: '',
        };
  }

  getMessageCount(room: ChatRoom) {
    return room.unread_messages > 9 ? '9+' : room.unread_messages;
  }

  /**
   * download the .pdf file from firestore storage
   * @param message
   */
  download(message: Message): void {
    this.chatService
      .downloadFile(message?.msg!)
      .subscribe((pdf) => saveAs(pdf, message.name));
  }

  trackByFn(index: any, item: any) {
    return item.id; // or item.id
  }

  trackUserByFn(index: number, item: any) {
    return item.id; // or item.id
  }

  scrollToBottom() {
    const maxScroll = this.list?.nativeElement.scrollHeight! + 1000;
    this.list?.nativeElement.scrollTo({ top: maxScroll, behavior: 'instant' });
  }
  /**
   * scroll message box to the bottom on message send
   * @param event
   */
  handleScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.chatService
        .markAllAsRead(this.selectedRoom, this.currentUserID)
        .pipe(first())
        .subscribe();
    }
  }

  onEnter(event: any) {
    if (this.message.length > 0 && event.key === 'Enter') {
      this.sendMessage();
    }
  }

  isActiveRoom(roomID: number | undefined) {
    return roomID === 0 ? false : this.selectedRoom?.id === roomID;
  }

  handleShowChat() {
    this.showChat = !this.showChat;
  }

  get currentUserID() {
    return `${this.user?.id}`;
  }

  get companyID(): string {
    return `${this.user?.company?.id}`;
  }
  isLink(message: Message) {
    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    return message.msg?.match(regex);
  }

  createGallery() {
    const lightboxRef = this.gallery.ref('lightbox');

    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Bottom,
      counter: false,
    });
    const images = this.messageCollection
      .filter((p) => p.msgType === MessageType.IMAGE)
      .map(
        (item) =>
          new ImageItem({
            src: item.msg?.replace(
              'https://ik.imagekit.io/yrgrf6bki4/tr:w-300,h-300',
              'https://firebasestorage.googleapis.com'
            ),
            thumb: item.msg,
          })
      );

    const videos = this.messageCollection
      .filter((p) => p.msgType === MessageType.VIDEO)
      .map(
        (item) =>
          new VideoItem({
            src: [{ url: item.msg, type: 'video/mp4' }],
            thumb: item.thumbnail,
            controls: true,
            autoplay: true,
          })
      );

    const youtubeVideo = this.messageCollection
      .filter(
        (p) =>
          p.msgType === MessageType.TEXT && p.msg?.indexOf('youtube') !== -1
      )
      .map(
        (item) =>
          new YoutubeItem({
            src: getYoutubeID(item.msg),
          })
      );
    const pdf = this.messageCollection
      .filter((p) => p.msgType === MessageType.PDF)
      .map(
        (item) =>
          new IframeItem({
            src: `/pdf?pathname=${new URL(item.msg!).pathname}&search=${
              new URL(item.msg!).search
            }`,
            thumb: `/assets/images/pdf.png`,
          })
      );
    this._album = [...images, ...videos, ...youtubeVideo, ...pdf];
    lightboxRef.load(this._album);
  }

  handleOpen(msg: Message) {
    this._album = [];
    this.createGallery();
    const index = this._album.findIndex((p) => p.data?.src === msg.msg);

    this.lightbox.open(index, 'lightbox', { panelClass: 'fullscreen' });
  }
}

const getYoutubeID = (url: string | undefined): string => {
  const youtubeIDregex = /(?:\?v=|\/watch\?v=|\/watch\/)([\w-]{11})/;
  const match = url?.match(youtubeIDregex);
  return match !== undefined && match !== null ? match[1] : 'hI5jggQTAsM';
};
export interface ActiveUser {
  roomID: number;
  logo: string;
  name?: string;
  lastSeen: string;
  lastMessage?: string;
}
export const sortRoomByDate = (a: ChatRoom, b: ChatRoom) =>
  new Date(b.lastUpdated as Date).getTime() -
  new Date(a.lastUpdated as Date).getTime();
