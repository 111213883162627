import { getMessage } from './utlity/exceptions';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { ARPAPI } from './interceptor-context';
import { StorageService } from './storage.service';
import { ToastService } from './toast.service';
import { environment } from 'src/environments/environment';
import { is } from 'date-fns/locale';
@Injectable()
export class ARPApiInterceptor implements HttpInterceptor {

  constructor(private router: Router, private storageService: StorageService, private toastService: ToastService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(ARPAPI)) {
      const token = this.storageService.get<string>('Authorization')!;
      const isEmbedded = this.storageService.get<boolean>('isEmbedded')!;
      
      const urlParameters = new URLSearchParams(window.location.search);
      const source = urlParameters.get('source') || '';

      request = isEmbedded ? headerWithToken(request, token, source) : headerWithJWT(request, token, source);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse,caught: Observable<HttpEvent<unknown>>) => {
        // to stop the loop when oAuth return same ARPToken in JWTtoken payload against the code exchange.
        if (error.status === 401) {
          const sessionID = this.storageService.get('sessionID')
          const logoutURL = `https://agridence-development.us.auth0.com/oidc/logout?post_logout_redirect_uri=${environment.BASE_URL}/logout&client_id=MIu2NVmRv2l1GdkFSeoGh3YUeeucXSNO&logout_hint=${sessionID}`
          window.location.href = logoutURL;
        }
        const message =getMessage(error);
        this.toastService.showErrorToast(message.title, message.body);
        console.error(error.statusText,error.message);
        return of();
        // return throwError(() => new Error(getMessage(error).body));
      })
    );
  }
}
export const headerWithJWT = (request: HttpRequest<unknown>, token: string, source: string) => {
  return request.clone({ headers: 
    request.headers
      .set(`Authorization`, `Bearer ${token}`)
      .set(`X-Client-Type`, source)
  });
}
export const headerWithToken = (request: HttpRequest<unknown>, token: string, source: string) => {
  return request.clone({ headers: 
    request.headers
      .set(`Authorization`, `Token ${token}`)
      .set(`X-Client-Type`, source)
    });
}


