import { environment } from 'src/environments/environment';
import { StorageService } from './../storage.service';
import { Component, OnInit } from '@angular/core';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit{
  constructor(private storageService:StorageService,private toastService:ToastService) {}
  ngOnInit(): void {
    this.storageService.clear();

  }

}
