import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard/auth.guard';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { ChatBoxParentComponent } from './chat-box-parent/chat-box-parent.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: '', component: LogoutComponent },
  {
    path: 'chat',
    component: ChatBoxParentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chat/:id',
    component: ChatBoxParentComponent,
    canActivate: [AuthGuard],
  },
  { path: 'pdf', component: PdfViewerComponent, canActivate: [AuthGuard] },
  { path: 'token', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
