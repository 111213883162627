import { HttpErrorResponse } from "@angular/common/http";

export const exceptionMessage = Object.freeze([
  { code: 0, title: 'Network connectivity issue', body: 'Unable to connect server!' },
  { code: 401, title: 'Unauthorized Access', body: '' },
  { code: 500, title: 'Some thing bad occur in BackEnd', body: '' },
  { code: 403, title: 'Forbidden', body: '' },
  { code: 400, title: 'Bad request', body: '' }
])
export const getMessage = (error: HttpErrorResponse) => exceptionMessage.find(p => p.code === error.status) ?? { code: error.statusText, title: 'Unknown Error', body: error.statusText };
