import { StorageService } from './../storage.service';
import { LoginManagerService } from './login.manager';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserService } from '../user/user.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email!: string;
  password!: string;
  constructor(
    private loginManager: LoginManagerService,
    private storageService: StorageService,
    private activateRoute: ActivatedRoute,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initiateLogin();
  }
  /**
   * This method will check if the ARPToken is set in the storage or not.
   * If the ARPToken is set then it will redirect to the chat page.
   * If the code is not set then it will call the loginWithOAuth method of loginManager service.
   */
  initiateLogin() {
    if (this.isARPTokenSet || this.ARPTokenInQuery) {
      this.storageService.store('source', this.source);
      this.redirectToChatWithToken();
    } else {
      this.loginWithOAuth();
    }
  }

  get isCodeFlow(): boolean {
    return this.code !== null;
  }

  get isARPTokenSet(): boolean {
    return this.storageService.check('Authorization');
  }
  get ARPTokenInQuery(): string {
    return this.activateRoute.snapshot.queryParamMap.get('token')!;
  }
  get source(): string {
    return this.activateRoute.snapshot.queryParamMap.get('source')!;
  }
  get code(): string {
    return this.activateRoute.snapshot.queryParamMap.get('code')!;
  }

  loginWithRedirect(): void {
    const loginURL = this.deviceService.isMobile()
      ? environment.oAuthMobileURL
      : environment.oAuthLoginURL;
    window.location.href = loginURL;
  }

  async loginWithOAuth() {
    if (this.isCodeFlow) {
      const code = this.activateRoute.snapshot.queryParamMap.get('code');
      await this.loginManager.loginWithAgridence(code);
      this.redirectToChatWithToken();
    } else {
      this.loginWithRedirect();
    }
  }

  redirectToChatWithToken() {
    if (!this.storageService.check('Authorization')) {
      this.storageService.store('Authorization', this.ARPTokenInQuery);
    }
    if (this.ARPTokenInQuery) {
      this.storageService.store('isEmbedded', true);
    }
    this.userService.userInfo().subscribe(() => {
      this.router.navigate(['chat'], { replaceUrl: true });
    });
  }
}
