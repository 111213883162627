import { UserManagerService } from './user/user.manager';
import { Component, OnInit } from '@angular/core';
import { ToastEvent } from './model/toast-event';
import { OneSignal } from 'onesignal-ngx';
import { SwUpdate } from '@angular/service-worker';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CHAT APP ARP';
  currentToasts: ToastEvent[] = [];
  constructor(private userManager: UserManagerService, private oneSignal: OneSignal, private swUpdate: SwUpdate) {
    this.oneSignal.init({
      appId: "b266c80e-16dd-4d62-8d97-975961c2039f",
    })


  }
  ngOnInit(): void {
    this.userManager.pingToServer();

    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(newversion => {
        if (newversion) {
          if (confirm("New version available. Load New Version?")) {
            window.location.reload();
          }
        }
      })
    }
  }
}
