import { catchError, lastValueFrom, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '../model/message.model';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'linkPreview'
})
export class LinkPreviewPipe implements PipeTransform {
  constructor(private httpClient: HttpClient) { }

  transform(message: Message, ...args: unknown[]): Observable<LinkPreview> {
    const link = message.msg;

    return this.httpClient
      .post<LinkPreview>(environment.CHATSERVERAPI.PREVIEWURL, { link })
      .pipe(
        catchError((error) => {
          // it's important that we log an error here.
          // Otherwise you won't see an error in the console.
          console.error("Unable to load preview", error);
          return of();
        })
      );

  }

}
export interface LinkPreview {
  url: string
  title?: string
  siteName: string
  description: string
  mediaType: string
  contentType: string
  images: string[]
  videos: any[]
  favicons: string[]
}
