export const environment = {
  BASE_URL: 'https://chat-infra.agridence.com',
  allowedOrigin:['http://localhost:4200'],
  oAuthTokenURL:'https://agridence-development.us.auth0.com/oauth/token',
  oAuthLoginURL:'https://agridence-development.us.auth0.com/authorize?response_type=code&client_id=MIu2NVmRv2l1GdkFSeoGh3YUeeucXSNO&redirect_uri=https://chat-infra.agridence.com/token/&scope=openid%20profile%20email&',
  oAuthMobileURL:'https://agridence-development.us.auth0.com/authorize?response_type=code&client_id=MIu2NVmRv2l1GdkFSeoGh3YUeeucXSNO&redirect_uri=https://chat-infra.agridence.com/token/&scope=openid%20profile%20email&',
  firebaseConfig: {
    apiKey: "AIzaSyAyTVDwgyyOpM50iJiY7sq5pckYUJmV0R4",
    authDomain: "hcplatform-infra-internal-dev.firebaseapp.com",
    projectId: "hcplatform-infra-internal-dev",
    storageBucket: "hcplatform-infra-internal-dev.appspot.com",
    messagingSenderId: "813678965885",
    appId: "1:813678965885:web:47b7b623ef3607a488ed14",
    measurementId: "G-M4PDC888GR",
    vapidKey: "BEZ7qe7H2KJ_qDCZE2L2quD5Fp9Np41btgVFgBW1eQuSKgxiEq2hwl7i9Kx_wJuCcFP0bTtnUhf9BmV50Cueczs"
  },
  CHATSERVERAPI: Object.freeze({
    CREATEUSER: `https://asia-southeast1-hcplatform-infra-internal-dev.cloudfunctions.net/api/user/create`,
    CREATEROOM: `https://asia-southeast1-hcplatform-infra-internal-dev.cloudfunctions.net/api/chat/room/create`,
    TOKEN: `https://asia-southeast1-hcplatform-infra-internal-dev.cloudfunctions.net/api/token`,
    NOTIFICATION: `https://asia-southeast1-hcplatform-infra-internal-dev.cloudfunctions.net/api/notify`,
    PREVIEWURL:`https://asia-southeast1-hcplatform-infra-internal-dev.cloudfunctions.net/api/link-preview`,
    THUMBNAIL:`https://asia-southeast1-hcplatform-infra-internal-dev.cloudfunctions.net/api/video/thumbnail`

  }),
  ARPAPI: Object.freeze({
    LOGIN: 'https://api-platform-testing.agridence.com/api/v1/auth/login/',
    USERINFO: 'https://api-platform-testing.agridence.com/api/v1/user/info/',
    CHATROOMS: 'https://api-platform-testing.agridence.com/api/v1/chatroom/?page=1&page_size=1000',
    MESSAGES: `https://api-platform-testing.agridence.com/api/v1/chatroom/`
  })
};
