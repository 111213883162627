import { HttpContextToken, HttpContext } from "@angular/common/http";

export const CHATAPI = new HttpContextToken<boolean>(() => false);
export const FIRESTOREAPI = new HttpContextToken<boolean>(() => false);
export const ARPAPI = new HttpContextToken<boolean>(() => false);



export const chatAPI=() =>{
  return new HttpContext().set(CHATAPI, true);
}

export const firestoreAPI=()=> {
  return new HttpContext().set(FIRESTOREAPI, true);
}
export const arpAPI=()=> {
  return new HttpContext().set(ARPAPI, true);
}
