import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quotation-display',
  templateUrl: './quotation-display.component.html',
  styleUrls: ['./quotation-display.component.scss']
})
export class QuotationDisplayComponent {
  @Input() quote: any;
}
