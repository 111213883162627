<div class="trade-info">
  <div class="header">{{quote?.producer?.name}}-{{quote?.consumer?.name}}</div>

  <div class="infomation">
      <div class="header-text">Date : </div>
      <div class="value-text">
          <div>{{quote?.last_modified_at | date: 'dd/MM/yyyy'}}</div>
      </div>
      <div class="header-text">Quotation : </div>
      <div class="value-text">
          <div>{{quote?.name || '-'}}</div>
      </div>
      <div class="header-text">Factory : </div>
      <div class="value-text">
          <div>{{quote?.factory?.name  || '-'}}</div>
      </div>
      <div class="header-text">Port : </div>
      <div class="value-text">
          <div>{{quote?.port?.name  || '-'}}</div>
      </div>
      <div class="header-text">Symbol : </div>
      <div class="value-text">
          <div>{{quote?.factory?.factory_code  || '-' }}</div>
      </div>
      <div class="header-text">Grade : </div>
      <div class="value-text">
          <div>{{quote?.grade?.name  || '-'}}</div>
      </div>
      <div class="header-text">Packing : </div>
      <div class="value-text">
          <div>{{quote?.packing?.name  || '-'}}</div>
      </div>

      <div class="header-text">Incoterm : </div>
      <div class="value-text">
          <div>{{quote?.shipping?.incoterm  || '-'}}</div>
      </div>

      <div class="header-text">Payment Term : </div>
      <div class="value-text">
          <div>{{quote?.payment_term?.name || '-'}}</div>
      </div>

      <div class="header-text">Destination : </div>
      <div class="value-text">
          <div>{{quote?.destination?.name || '-'}}</div>
      </div>

      <div class="header-text">Month : </div>
      <div class="value-text">
          {{quote?.delivery_date | date: "MMM / yyyy"}}
      </div>
      <hr class="line">
      <div class="header-price">Ask Price (USC/KG) : </div>
      <div class="value-price">
          {{(quote?.ask_price ) || '-' }}
      </div>
      <div class="header-price">Ask Volume (mT) : </div>
      <div class="value-price">
          {{(quote?.ask_volume | number: '1.2-2') || '-' }}
      </div>

      <div class="header-price">Bid Price (USC/KG) : </div>
      <div class="value-price">
          {{(quote?.bid_price ) || '-' }}
      </div>
      <div class="header-price">Bid Volume (mT) : </div>
      <div class="value-price">
          {{(quote?.bid_volume | number: '1.2-2') || '-' }}
      </div>
  </div>
  <div class="quote_status">{{quote?.status || '-'}}</div>

</div>
