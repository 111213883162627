import { ToastComponent } from './toast/toast.component';
import { firebaseConfig } from './config/firebase.config';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { FormsModule } from '@angular/forms';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { LoginComponent } from './login/login.component';
import { SkipCurrentUserPipe } from './skip-current-user.pipe';
import { QuotationDisplayComponent } from './quotation-display/quotation-display.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ARPApiInterceptor } from './arp-api.interceptor';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FileSizePipe } from './pipes/file-size.pipe';
import { LinkPreviewPipe } from './pipes/link-preview.pipe';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ToasterComponent } from './toaster/toaster.component';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { LogoutComponent } from './logout/logout.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { ChatListMobileComponent } from './chat-list-mobile/chat-list-mobile.component';
import { ChatBoxParentComponent } from './chat-box-parent/chat-box-parent.component';
import { StorageService } from './storage.service';
@NgModule({
  declarations: [
    AppComponent,
    ChatBoxComponent,
    LoginComponent,
    SkipCurrentUserPipe,
    QuotationDisplayComponent,
    FileSizePipe,
    LinkPreviewPipe,
    EllipsisPipe,
    ToastComponent,
    ToasterComponent,
    PdfViewerComponent,
    LogoutComponent,
    ChatListMobileComponent,
    ChatBoxParentComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideFirestore(() => getFirestore()),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    HttpClientModule,
    PickerModule,
    GalleryModule,
    LightboxModule,
    NgxExtendedPdfViewerModule,
    ServiceWorkerModule.register('OneSignalSDKWorker.js'),
  ],
  providers: [
    SkipCurrentUserPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ARPApiInterceptor,
      multi: true,
    },
    StorageService,
    {
      provide: SwRegistrationOptions,
      useFactory: (storageService: StorageService) => {
        const isMobile = storageService.get('source') === 'mobile';

        return {
          enabled: !isMobile && !isDevMode(),
          registrationStrategy: 'registerWhenStable:30000',
        };
      },
      deps: [StorageService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
