import { StorageService } from './../storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment'

import { UserInfo } from '../model/user-info.model';
import { arpAPI } from '../interceptor-context';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient, private storageService: StorageService) {

  }
  userInfo(): Observable<UserInfo> {
    return this.httpClient.get<UserInfo>(environment.ARPAPI.USERINFO, {
      context: arpAPI()
    }).pipe(tap(this.setUserProfile));
  }
  private setUserProfile = (user: UserInfo) => {
    this.storageService.store('user_profile', user);

  }
}
