import { Pipe, PipeTransform } from '@angular/core';
import { ChatRoom } from './model/chat-room.model';

@Pipe({
  name: 'skipCurrentUser'
})
export class SkipCurrentUserPipe implements PipeTransform {

  transform(users:ChatRoom[],skipUser:string[]): Array<ChatRoom> {
    return users.filter(user =>  !skipUser.includes(user.id?.toString()!)  );
  }

}
