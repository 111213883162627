import { StorageService } from './../storage.service';
import { FIRESTORE } from '../config/firebase.config';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { timer, map, Observable, from, of } from "rxjs";
import * as firebase from 'firebase/firestore';
import { UserInfo } from "../model/user-info.model";
import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { OneSignal } from 'onesignal-ngx';
import { v4 as uuidv4 } from 'uuid';
const PING_INTERVAL = 100000;
@Injectable({
  providedIn: 'root'
})

export class UserManagerService {
  constructor(private firestore: AngularFirestore, private userService: UserService,
    private storageService: StorageService,
    private oneSignal: OneSignal,
    private fireMessaging: AngularFireMessaging) { }
  pingToServer = () => {
    if (this.storageService.check('user_profile')) {
      const currentUser = this.storageService.get<UserInfo>('user_profile')?.id;
      timer(0, PING_INTERVAL).pipe(
        map(() => { this.updateUserStatus(`${currentUser}`, { isOnline: true, lastSeen: firebase.serverTimestamp() }) }),
      ).subscribe();
      //this.requestPushNotificationsPermission();
      this.registerOneSignalID();
    }
  }
  updateUserStatus = (docid: string, data: any): Observable<void> => {
    if (docid === '') return of();
    const userref = this.firestore.collection(FIRESTORE.COLLECTION.USERS).doc(docid);
    return from(userref.update(data));
  }
  updateLastMessage = (docid: string, lastMessage: string): Observable<void> => {
    if (docid === '') return of();
    const userref = this.firestore.collection(FIRESTORE.COLLECTION.USERS).doc(docid);
    return from(userref.update({ lastMessage }));
  }
  userInfo(): Observable<UserInfo> {
    return this.userService.userInfo();
  }
  requestPushNotificationsPermission() {
    const currentUser = this.storageService.get<UserInfo>('user_profile')?.id;
    this.fireMessaging.requestToken.subscribe({
      next: token => {
        console.log('Permission granted! Save to the server!', token);
        this.updateUserStatus(`${currentUser}`, { fcmid: token })
      },
      error: err => {
        console.error('Fetching FCM token failed: ', +err)
      }
    })
  }
  registerOneSignalID() {
    const currentUser = this.storageService.get<UserInfo>('user_profile')?.id;

    this.oneSignal.setExternalUserId(`${currentUser}`).then(token => {
      console.log('OneSignal token: ', token);

    })
  }

}
