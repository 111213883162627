// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// export const firebaseConfig = {
//   apiKey: "AIzaSyB2J2qRpPAFn5gc41bTo7cj7S7DVgGfDAg",
//   authDomain: "chat-app-b833d.firebaseapp.com",
//   databaseURL: "https://chat-app-b833d-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "chat-app-b833d",
//   storageBucket: "chat-app-b833d.appspot.com",
//   messagingSenderId: "626689352512",
//   appId: "1:626689352512:web:45c42947082b0b4abb50a5",
//   measurementId: "G-XZ6Y4CCL5M",
//   vapidKey: "BNu6LJ_KzDPvbUfRmkZT3tqFdfc146Rislp__qxxEQeHfWxV_u07yB1kSeeTHl9Plo5V_Xfrbi9w7Lmnt4dZh4o"
// };

export const FIRESTORE = {
  DOCUMENT: {

  },
  COLLECTION: {
    USERS: "users",
    MESSAGES: "messages",
    ROOMS: "rooms"

  }
}


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAyTVDwgyyOpM50iJiY7sq5pckYUJmV0R4",
  authDomain: "hcplatform-infra-internal-dev.firebaseapp.com",
  projectId: "hcplatform-infra-internal-dev",
  storageBucket: "hcplatform-infra-internal-dev.appspot.com",
  messagingSenderId: "813678965885",
  appId: "1:813678965885:web:47b7b623ef3607a488ed14",
  measurementId: "G-M4PDC888GR",
  vapidKey: "BEZ7qe7H2KJ_qDCZE2L2quD5Fp9Np41btgVFgBW1eQuSKgxiEq2hwl7i9Kx_wJuCcFP0bTtnUhf9BmV50Cueczs"
};

//  

